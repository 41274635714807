@import '../../assets/definitions';


.btn {
    border: none;
    outline: none;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    text-align: center;
    height: 50px;
    padding: 0 30px;
    min-width: 200px;
    align-items: center;
    justify-content: center;

    &::first-letter {
        text-transform: uppercase;
    }
    
    &-primary {
        background: $lkv-Green !important;

        &:hover {
            background: darken($lkv-Green, 10%);
            transition: .2s ease-in-out;
        }
        
    }
    &-secondary {
        background: $lkv-Brown;

        &:hover {
            background: darken($lkv-Brown, 10%);
            transition: .2s ease-in-out;
        }
        
    }

    @media only screen and (max-width: 576px) { 
        height: 3.5rem;
        max-width: 14rem;
        min-width: 14rem;
        font-size: 1.2rem;
    }

    
}

.notfound-btn {
    padding: 15px 30px;
    margin-top: 20px;
}