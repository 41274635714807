@import "./assets/utils";

* {
    font-family: $baseFontFamily;
}
body {
    background: #f0ebea;
    overflow-x: hidden;
    padding-bottom: 4rem;
}
// CONFIRM BOX
.react-confirm-alert-overlay {
    z-index: 9999 !important;
    .confirm {
        &.custom-ui {
            background: white;
            font-family: $baseFontFamily;

            width: 100vw;
            height: 100vh;
            padding: 20px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .actions {
                display: inline-grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

.active .furniture__image {
    border: .2px royalblue solid;
}

.furniture__image {
    animation: flash-furniture-in 1s 1;
}