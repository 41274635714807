.floor_plan {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    

    h1 {
        margin-top: -50px;
    }

    button.clearFloor {
        outline: none;
        border: none;
        background: black;
        color: white;
        height: 50px;
        left: 100px;
        top: -65px;
        position: relative;

        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.5);
        }
    }

    .dropdown {
        @extend button.clearFloor;
        display: inline-flex;
    }
}


@keyframes flash-furniture-in {
    0% { 
      box-shadow: 0px 0px 5px -3px rgba(65,105,225,0.75);
    -webkit-box-shadow: 0px 0px 5px -3px rgba(65,105,225,0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(65,105,225,0.75);
         }
    50% {
        box-shadow: 0px 0px 5px 4px rgba(65,105,225,0.75);
        -webkit-box-shadow: 0px 0px 5px 4px rgba(65,105,225,0.75);
        -moz-box-shadow: 0px 0px 5px 4px rgba(65,105,225,0.75);
  }
    100% { 
        box-shadow: 0px 0px 5px -3px rgba(65,105,225,0.75);
        -webkit-box-shadow: 0px 0px 5px -3px rgba(65,105,225,0.75);
        -moz-box-shadow: 0px 0px 5px -3px rgba(65,105,225,0.75); }
}