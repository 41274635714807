.furniture-menu-item {
    transition: 0.1s linear;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 10px auto;

    p {
        font-size: 15px;
        color: #505053;
    }

    &-wrap {
        position: relative;
        height: 100%;
        width: 100%;
        height: 70px;

        &:hover {
            cursor: pointer;
        }
    }

    &__overlay {
        position: absolute;
        width: 60px;
        height: 60px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.1s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 46px;
    }

    img {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;

        &:active {
            cursor: none;
        }

        &.shadow {
            -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
        }
    }
}
