main.main {
    @media only screen and(max-width: 1023px) {
        display: none;
    }
    @media only screen and(max-height:715px) {
        display: none;
    }
    width: 100vw;
    height: calc(100vh - 60px);
    top: 0;
    left: 0;

    display: grid;
    place-items: center;

    margin: 0px auto;
}
.floor_plan__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1000px 150px;

    @media only screen and (min-width: 1750px) {
        grid-template-columns: 205px 1000px 465px; // 1700px
        grid-gap:20px;
    }
    place-items: center;
    place-content: center;

    position: relative;
}
