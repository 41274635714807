/**
 * Define your own color, fonts etc. in here
 * 
 */

// Benton sans
@font-face {
	font-family: "BentonSans";
	src: url("../assets/fonts/BentonSans/BentonSans-Book.otf");
	font-weight: 350;
	font-style: "normal";
}
@font-face {
	font-family: "BentonSans";
	src: url("../assets/fonts/BentonSans/BentonSans-Regular.otf");
	font-weight: 400;
	font-style: "normal";
}
@font-face {
	font-family: "BentonSans";
	src: url("../assets/fonts/BentonSans/BentonSans-Medium.otf");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "BentonSans";
	src: url("../assets/fonts/BentonSans/BentonSans-Bold.otf");
	font-weight: 600;
	font-style: "normal";
}
// Benton Modern
@font-face {
	font-family: "BentonModern";
	src: url("../assets/fonts/BentonModern/BentonModDisp-Regular.otf");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "BentonModern";
	src: url("../assets/fonts/BentonModern/BentonModDisp-Light.otf");
	font-weight: 200;
	font-style: normal;
}
// Dulan Anzelica
@font-face {
	font-family: 'DulanAnzelica';
	src: url('../assets/fonts/DulanAnzelica/DulanAnzelica.otf');
	font-weight: 400;
	font-style: 'normal';
}

$baseFontFamily: "BentonSans";
$baseFontBook: $baseFontFamily; // Same font different weight
$baseFontRegular: "BentonModern";
$baseFontDulan: "DulanAnzelica";

$baseFontColor: #303030;

$fontColor: #505053;
$fontHeaderColor: #343434;

$lkv-Green: #849884;
$lkv-Black: #000000;
$lkv-Brown: #c1b2a2;
$lkv-Brown-light: #efecea;
$lkv-White: #fff;

// Contentblocks start

$imgbox-height: 75vh;
// $imgbox-height: 960px;
$image-block-container-padding: 40px 0;
$image-block-container-padding-mobile: 20px 0;

// Contentblocks end

$lkv-page-bg: $lkv-Brown-light;
$lkv-footer-bg: $lkv-Brown;

$lkv-btn-primary: $lkv-Brown;
$lkv-btn-secondary: $lkv-Green;
