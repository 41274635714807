$furniture-menu-width: 465px;

.furniture-menu {
    height: 100%;
    padding: 0 20px;

    &-item {
        p {
            text-align: center;
        }
    }

    .instructions {
        display: none;
        background: white;
        &.visible {
            display: block;
            position: absolute;
            width: 100%;
            // left: 0;
            right: 0;
            bottom: 0;
            padding: 0 80px 40px 80px;
            box-sizing: border-box;
            p {
                text-align: left;
                letter-spacing: 0.03rem;
                color: #505053;
                opacity: 1;
                font-weight: 200;

                b {
                    font-weight: bold;
                }
            }
        }
    }

    .indiana-scroll-container {
        display: flex;
        flex-direction: row;
        margin: 0 auto;

        .row {
            margin: 10px;

            &:hover {
                cursor: grab;
            }
        }
        &:hover {
            cursor: grab;
        }
    }

    &__wrapper {
        // overflow: hidden;
        border-right: 12px solid #d9cec6;
        height: 100%;
        max-height: 700px;

        min-width: $furniture-menu-width;
        // margin-left: 20px;
        @media only screen and (min-width: 1750px) {
            // margin-left: 18px;
            margin-left: -12px;
        }
        position: absolute;

        button {
            z-index: 99;
            width: 30px;
        }

        button.frontpage {
            bottom: -40px;
            right: -15px;
            outline: none;
            border: none;
            background: none;
            font-weight: bold;
        }

        button.hud_close {
            width: 30px;
            height: 50px;
            outline: none;
            border: none;
            background: none;
            font-size: 1.3rem;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__categories {
        &.categories__big {
            max-height: 400px;
            overflow-y: scroll;
            box-sizing: border-box;
            padding: 0 60px;

            // // HIDE SCROLLBAR START
            // &::-webkit-scrollbar {
            //     display: none;
            // }
            -ms-overflow-style: thin; /* IE and Edge */
            scrollbar-width: thin; /* Firefox */

            scrollbar-color: #d9cec6 #f2f2f2;
            &::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f2f2f2;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #d9cec6;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: lighten(#d9cec6, 2%);
            }

            .indiana-scroll-container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                grid-gap: 20px 20px;
                padding-bottom: 35px;
            }
        }
        &.categories__small {
            display: flex;
            margin: 20px auto;
            position: relative;

            &::before {
                background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
                width: 40px;
                content: "";
                display: block;
                position: absolute;
                left: 0px;
                height: 100%;
                top: -50%;
                z-index: 999;
                transform: translateY(10px);
            }

            &::after {
                background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
                width: 33px;
                content: "";
                display: block;
                position: absolute;
                right: 0px;
                height: 100%;
                top: -50%;
                z-index: 999;
                transform: translateY(10px);
            }

            img {
                display: none;
            }
            .category__wrapper {
                p {
                    text-align: center;
                    padding: 0 10px;
                }
                &:first-of-type {
                    margin-left: 30px;
                }
                &:last-of-type {
                    padding-right: 30px;
                }
            }
        }

        .category__wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 50px;
            // max-width: 150px;

            img {
                padding: 20px;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                object-fit: contain;
                align-self: center;
            }
            p {
                margin: 0;
                text-align: left;
                font-weight: 200;
                color: #505053;

                &.active {
                    text-decoration: none;
                    font-weight: bold;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &__furnitures {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 140px;
        width: 100%;
        grid-gap: 20px 20px;
        box-sizing: border-box;
        padding: 0 60px;
        border-bottom: 60px solid white;

        height: 485px;

        overflow-y: scroll;

        // HIDE SCROLLBAR START
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        // HIDE SCROLLBAR END
    }
}
